import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../axios/axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ServiceName = () => {
  const { id } = useParams();
  // const [servicerName, setServicerName] = useState("")
  // const [servicerAccountId, setServicerAccountId] = useState("")
  const statusFlg = useSelector((state) => {
    return state?.accountDetail?.status;
  });
  const getServicerNameFromRedux = useSelector((state) => {
    return state?.accountDetail?.ServicerName;
  });
  const getServicerAccountNumberFromRedux = useSelector((state) => {
    return state?.accountDetail?.ServicerAccountNumber;
  });
  const getAccountDetails = async () => {
    let response;
    if (statusFlg == "Set up for Dispatch") {
      response = await getRequest(
        `/api/getServicerAccount?servicerId=${id}`,
        "sdProvider"
      );
    } else if (statusFlg == "Inactive Servicer") {
      response = await getRequest(
        `/api/getServicerAccount?servicerId=${id}`,
        "sdProvider"
      );
    } else {
      response = await getRequest(
        `/api/getServicerAccount?servicerId=${id}`,
        "sdProvider"
      );
    }
    if (response?.data?.isSuccess) {
      // setServicerName(getServicerNameFromRedux ? getServicerNameFromRedux : response?.data?.data?.accountData.account.businessName);
      // setServicerAccountId(response?.data?.data?.accountData.account.accountNumber);
      window.localStorage.setItem("isdCode", response?.data?.data?.isdCode);
      window.localStorage.setItem(
        "phoneRegionCode",
        response?.data?.data?.phoneRegionCode
      );
      window.localStorage.setItem(
        "servicerName",
        response?.data?.data?.accountData?.account?.businessName
      );
      window.sessionStorage.setItem(
        "servicerId",
        response?.data?.data?.servicerId
      );
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, [id]);

  return (
    <>
      <Typography sx={{ color: "#1E384B", fontSize: "14px" }}>
        {getServicerNameFromRedux
          ? `${getServicerNameFromRedux ? getServicerNameFromRedux : ""}  ${
              getServicerAccountNumberFromRedux
                ? `${"(" + getServicerAccountNumberFromRedux + ")"}`
                : null
            }`
          : ""}
      </Typography>
    </>
  );
};
export default ServiceName;
